// Overwrite templates variables here

// Modify template colors
$primary:		#595BD4;
$danger:		#FD3D39;
$warning:		#FFCB2F;
$success:		#53D86A;



// Import the template source code
@import "edms";

// Your custom code goes here
.be-top-header .page-nav-top {
    font-size: 1.55rem;
    float: left;
    flex: 1 1 auto;
    padding: 0 20px;
    color: #404040;}

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
.be-top-header .be-navbar-header .navbar-brand {
    background-size: 125px 27px;
}
}

.be-offcanvas-menu .be-top-header .navbar-brand {
    width: 150px;
}


.be-top-header .be-navbar-header .navbar-brand {
    width: 150px;
}


.label-desc {
    text-transform: uppercase;
    font-size: .8462rem;
    color: #9d9d9d;
}

.aside-nav.reports-dash-nav {
    display: block;
}

.sinclair{float: right;width: 100%;}

.sinclair-logo {
    line-height: 60px;
    height: 27px;
    width: 125px;
    background-image: url($sinclairlogo-bg);
    background-repeat: no-repeat;
    background-position: 20px center;
    padding: 0 20px;
    margin: 0;
    display: block;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
.sinclair-logo {
    background-image: url($sinclairlogo-bg);
    background-size: 125px 27px;
}
}


.equal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .equal > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }
  